<template>
    <v-dialog v-model="dialog" width=900>
        <v-sheet>
            <v-toolbar color="green" dark>
                <v-toolbar-title>
                    Listado de comunicados
                </v-toolbar-title>
            </v-toolbar>
            <div class="pa-5">
                <v-data-table
                    elevation="2"
                    :headers="headers"
                    :items="datos"
                    disable-sort
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50]
                    }"
                    item-key="id"
                    :loading="loadingDatosTodos"

                    :server-items-length='totalRegistros'
                    :options.sync="optionsTable"
                >

                    <template v-slot:[`item.dateCreated`]="{ item }">
                        {{moment(item.dateCreated).format('DD-MM-YYYY HH:mm')}}
                    </template>

                    <template v-slot:[`item.acciones`]="{ item }">
                        <v-icon @click="cargaMensaje(item)">mdi-magnify</v-icon>
                    </template>

                </v-data-table>
            </div>
            <dialog-mensaje
                :dialogModal.sync = "dialogModalMensaje"
                :esteComunicado = "esteComunicado"
            />
        </v-sheet>

    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogListModal: Boolean,
    },
    components:{
        'dialog-mensaje':()=>import('@/views/admin/bb/MuestraComunicadosModal'),
    },

    data:()=>({
        headers:[
            {text:'Fecha', value:'dateCreated', align:'center', width: '20%'},
            {text:'Título', value:'titulo'},
            {text:'', value:'acciones', align:'center', width: '5%'},
        ],
        datos:[],
        loadingDatosTodos:false,
        totalRegistros:0,
        optionsTable:{},

        dialogModal:false,
        dialogModalMensaje:false,
        esteComunicado:{},

    }),

    methods:{
        cargaMensaje(item){
            console.log(item)
            this.esteComunicado = item
            this.dialogModalMensaje = true
        },
        async cargaMensajes(){
            //console.log('cargando mensajes')
            this.loadingDatosTodos = true
            try{
                let req = await this.$http({
                    method:'GET',
                    url: '/admin/listMensajes',
                    params:{
                        activo:true,
                    },
                })
                this.loadingDatosTodos = false
                this.datos = req.data.root
                this.totalRegistros = req.data.total
            } catch(err){
                this.loadingDatosTodos = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

    computed:{
        dialog:{
            get(){return this.dialogListModal},
            set(val){this.$emit('update:dialogListModal',val)}
        }
    },
    watch:{
        dialog(v){
            if (v){
                //console.log('jejejeje')
                this.cargaMensajes()
            }
        }
    },
}
</script>

<style>

</style>